<template>
  <div class="relative flex justify-center">
    <div class="absolute h-px inset-x-0 bg-slate-300 top-[calc(50%-1px)]"></div>

    <span
      class="text-sm bg-white relative px-4 py-1 cursor-default text-slate-600"
    >
      or
    </span>
  </div>
</template>
